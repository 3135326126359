import React from 'react';
import { useQuery, gql } from '@apollo/client';

import { useHistory, useParams, Route, Switch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { PAYMENT_AUTOPAY_PATH, PAYMENT_ONE_TIME_PATH, PAYMENT_SPECIAL_INVOICE_PATH } from '../constants/routes';
import { useUserAuthInfo } from '../components/auth';
import EnrollInAutopay from './EnrollInAutopay';
import OneTimePayment from './OneTimePayment';

const PAYMENT_PAGE_QUERY = gql`
  query GetFieldsForCybersourceQuery($prospectId: String!, $contactId: ID!) {
    contact(id: $contactId) {
      id
      firstName
      lastName
      email
      mobilePhone
      phone
    }
    prospect(prospectId: $prospectId) {
      id
      street
      city
      state
      zipCode
      billingSystem
      isVivintSolarPostPTO
      autopayByCreditCardEligible
      purchasedThru
      billing(contactId: $contactId) {
        id
        totalAmountDue
        lastDueDate
      }
      PTO
      fundBridge
    }
  }
`;

const PaymentPage = () => {
  const { isBillingEscalations } = useUserAuthInfo();
  const { prospectId, contactId } = useParams();
  const history = useHistory();
  const { loading, data } = useQuery(PAYMENT_PAGE_QUERY, { variables: { prospectId, contactId } });

  if (loading) return <LinearProgress />;

  const specialInvoiceAmount = history?.location?.state?.specialInvoiceAmount ?? null;
  const referenceNumberPrefix = history?.location?.state?.referenceNumberPrefix ?? null;

  const { firstName, lastName, email, mobilePhone, phone } = data?.contact || {};
  const {
    street,
    city,
    state,
    zipCode,
    fundBridge,
    billingSystem,
    isVivintSolarPostPTO,
    autopayByCreditCardEligible,
    purchasedThru,
  } = data?.prospect || {};
  const { totalAmountDue, lastDueDate } = data?.prospect?.billing || {};
  const userInfo = {
    prospectId,
    contactId,
    firstName,
    lastName,
    email,
    mobilePhoneNumber: mobilePhone,
    phoneNumber: phone,
    address: street,
    city,
    state,
    zip: zipCode,
    lastDueDate,
    totalAmountDue: String(totalAmountDue),
    fundBridge,
    billingSystem,
    isVivintSolarPostPTO,
    autopayByCreditCardEligible,
    purchasedThru,
    ptoDate: data?.prospect.PTO,
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Switch>
              <Route path={PAYMENT_AUTOPAY_PATH}>
                <EnrollInAutopay userInfo={userInfo} />
              </Route>
              <Route path={PAYMENT_ONE_TIME_PATH}>
                <OneTimePayment totalAmountDue={totalAmountDue} userInfo={userInfo} />
              </Route>
              <Route path={PAYMENT_SPECIAL_INVOICE_PATH}>
                <OneTimePayment
                  specialInvoiceAmount={specialInvoiceAmount}
                  referenceNumberPrefix={referenceNumberPrefix}
                  userInfo={userInfo}
                  isBillingEscalations={isBillingEscalations}
                />
              </Route>
            </Switch>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentPage;
